import { useCallback } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

import { useCreateDatasource as CE_useCreateDatasource } from "ce/PluginActionEditor/hooks/useCreateDatasource";
import { getIDETypeByUrl } from "ee/entities/IDE/utils";
import { getCurrentWorkflowId } from "ee/selectors/workflowSelectors";
import { builderURL, integrationEditorURL } from "ee/RouteBuilder";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import history from "utils/history";
import { DatasourceCreateEntryPoints } from "constants/Datasource";
import { IDE_TYPE } from "ee/IDE/Interfaces/IDETypes";

function useCreateDatasource() {
  const { pathname } = useLocation();
  const IDEType = getIDETypeByUrl(pathname);
  const { onCreateDatasourceClick: CE_onCreateDatasourceClick } =
    CE_useCreateDatasource();
  const workflowId = useSelector(getCurrentWorkflowId);

  const onCreateDatasourceClick = useCallback(
    (selectedTab, pageId?: string) => {
      let url = "";

      switch (IDEType) {
        case IDE_TYPE.None:
        case IDE_TYPE.App:
          return CE_onCreateDatasourceClick(selectedTab, pageId);
        case IDE_TYPE.Package:
          url = builderURL({
            suffix: "datasources/NEW",
            generateEditorPath: true,
          });
          break;
        case IDE_TYPE.Workflow:
          url = integrationEditorURL({
            workflowId,
            selectedTab,
          });
          break;
      }

      history.push(url);

      AnalyticsUtil.logEvent("NAVIGATE_TO_CREATE_NEW_DATASOURCE_PAGE", {
        entryPoint: DatasourceCreateEntryPoints.QUERY_EDITOR,
      });
    },
    [CE_onCreateDatasourceClick, IDEType, workflowId],
  );

  return { onCreateDatasourceClick };
}

export { useCreateDatasource };
